<template lang="pug">
    div.flex.flex-wrap.bg-dark-600.p-3.rounded.gap-5.items-center.justify-between(v-if='loaded')
        div.flex.flex-wrap.gap-5

            img(:src='"https://cdn.discordapp.com/icons/" + this.serverId + "/" + this.serverIcon + ".jpg"').w-16.rounded-full

            div
                h2.text-gray-200.font-bold {{ serverName }}

                div.flex.flex-wrap.gap-2.mt-1()
                    div.flex.flex-wrap.items-center
                        div.bg-green-400.w-2.h-2.rounded-full
                        p.text-gray-400.ml-2 {{ playersOnline }} Online
                    div.flex.flex-wrap.items-center
                        div.bg-gray-400.w-2.h-2.rounded-full
                        p.text-gray-400.ml-2 {{ playersTotal }} Members
        
        app-button(icon='fab discord' text :href='"https://discord.gg/" + inviteCode' redirect) Join

</template>

<script>
export default {
    props: {
        inviteCode: String
    },
    data() {
        return {
            playersOnline: 0,
            playersTotal: 0,
            serverId: 0,
            serverName: '',
            serverIcon: '',
            error: false,
            loaded: false
        }
    },
    methods: {
        async fetchInfo() {

            try {
                const result = await fetch(
                    'https://discordapp.com/api/v6/invite/' + this.inviteCode + '?with_counts=true',
                    {
                        method: 'GET',
                        credentials: 'include'
                    }
                ).then( res => res.json() );

                this.playersOnline = result.approximate_presence_count;
                this.playersTotal = result.approximate_member_count;
                this.serverId = result.guild.id;
                this.serverName = result.guild.name;
                this.serverIcon = result.guild.icon;
            } catch ( error ) {
                this.error = true;
            }

            this.loaded = true;
        }
    },
    async mounted() {
        await this.fetchInfo();
    }
}
</script>