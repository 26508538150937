import axios from 'axios'
import AxiosWrapper from './axios'

const apiAxios = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_STORE_API + '/',
    crossDomain: true
})

// Logs any errors to the console when in development.
apiAxios.interceptors.response.use(
    res => res,
    err => {
        //console.log('Response returned error: ' + err.request.response + '\n' + err)
        return err
    }
)

// Extremely basic class with static functions that alias Vue.axios
export default new AxiosWrapper(apiAxios);