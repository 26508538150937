<template lang="pug">

    div(v-if='pageFinishedLoading === false').mt-20.container.mx-auto.p-2.col-span-6.w-full.bg-dark-400.flex.items-center.justify-center.h-28
        h1.text-white.feature-font(class='md:text-lg') Loading...

    div.container.mx-auto.p-2.grid.grid-cols-6.mt-20.gap-10(v-else)
        div(class='col-span-6 lg:col-span-2')
            div.flex.flex-wrap.gap-5
                img(:src='userInfo.avatar').w-28.rounded
                div
                    h1.feature-font.text-white.text-3xl {{ userInfo.username }}
                    app-button.mt-3(icon='sign-out-alt' text @click.native='logout') Logout


            app-header(title='Link your account' icon='fab discord').mt-16

            p.text-gray-300.mt-2(v-if='userInfo.discordId') Your Discord account is linked. Click below to unlink.

            app-button(v-if='!userInfo.discordId' to='/link-accounts' icon='fab discord').mt-6 Link your Discord account
            app-button(v-else to='/link-accounts' icon='fab discord').mt-6 Unlink your Discord account

            //- div.mt-5(v-if='!userInfo.discordId')
            //-     p.text-gray-300 You have not linked your discord profile. Begin the linking process by clicking the button below.
            //-     app-button.mt-5(large icon='fab discord' @click.native='onLinkDiscord' :loading='loading') Link Discord
            //- div.mt-5(v-else)
            //-     p.text-gray-300 Your discord account, {{ userInfo.discordName }}, is linked.
            //-     app-button.mt-5(large icon='fab discord' @click.native='unlinkDiscord') Unlink Discord

        div(class='col-span-6 lg:col-span-4').flex.flex-col.gap-2
            // Payments table
            app-header(title='Purchase History' icon='gem')
                app-button(icon='gem' text :href='storeHref' redirect) Store

            table.vital-table.mt-10.mb-10
                thead
                    tr
                        th Package Name
                        th Quantity
                        th Purchase Date
                        th Expiry Date
                        th Status
                tbody
                    tr(
                        v-for='payment in payments'
                    )
                        //- :key='payment.paymentId'
                        td {{ payment.packageName }}
                        td {{ payment.quantity }}
                        td.text-center {{ (new Date(payment.purchasedAt)).toLocaleDateString() }}
                        td.text-center {{ !payment.expiresAt ? "Never" : (new Date(payment.expiresAt)).toLocaleDateString() }}
                        td.text-center {{ payment.transactionStatus }}

                    tr(v-if='!payments.length')
                        td(colspan='10000' ) No purchases to show.


        div.flex.flex-col.gap-2.col-span-6.mb-16.mt-16
            // Discord servers
            div.flex.flex-col.gap-2
                app-header.mb-5(title='Community Discord Servers' icon='fab discord')

                div.container.grid.grid-cols-2.gap-2
                    discord(
                        v-for='server in discordServers'
                        :key='server'
                        :invite-code='server'
                    )

            //- iframe.mt-5(
            //-     title="Discord" 
            //-     src="https://discord.com/widget?id=767209374355030026&amp;theme=dark" 
            //-     allowtransparency="true" 
            //-     frameborder="0" 
            //-     sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
            //- )
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AppHeader from '../components/App/Header.vue';
import { LINK_DISCORD, UNLINK_DISCORD, LOGOUT_USER } from '../store/types/actions';

import userPackagesService from '../services/userpackages.service';
import Discord from '../components/Discord';

export default {
    components: {
        AppHeader, Discord
    },
    data() {
        return {
            discordServers: ['6eyHUqEd4r', 'c6FHpEcfGc', 'nrut25VhYs', 'r5wtTE2BQr', 'esDhc7bG7n', 'gv338faVyE', 'XjxETmfAXD', 'SXDDAncBNv'],
            payments: [],
            storeHref: process.env.VUE_APP_STORE_URL,
            loading: false,
            pageFinishedLoading: false
        }
    },
    computed: mapGetters('user', ['userInfo']),
    methods: {
        onLinkDiscord() {
            this.loading = true;
            this.linkDiscord();
        },
        ...mapActions('user', {
            linkDiscord: LINK_DISCORD,
            unlinkDiscord: UNLINK_DISCORD,
            logoutAction: LOGOUT_USER
        }),
        logout() {
            this.logoutAction();
            this.$router.push('/');
        }
    },
    async mounted() {
        const result = await userPackagesService.getAsRecipient();
        if (result && result.data) {
            result.data.data.sort((a, b) => b.purchasedAt - a.purchasedAt);
            this.userPackages = result.data.data ? result.data.data : [];
        }

        this.pageFinishedLoading = true;
    }
}
</script>