import storeApiService from './store-api.service';

class UserPackagesService {
    getAsRecipient() {
        return storeApiService.get('userpackages/recipient/@self');
    }
    getAsPayer() {
        return storeApiService.get('userpackages/payer/@self');
    }
}

export default new UserPackagesService();